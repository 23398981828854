<template>
	<v-card flat outlined>
		<v-card-title>Items in cart</v-card-title>
		<v-card-subtitle v-show="lastUpdated">
			<v-icon small>mdi-clock-outline</v-icon> last updated
			{{ lastUpdated }}</v-card-subtitle
		>

		<v-data-table
			:items="cartItems"
			:headers="tableHeaders"
			disable-pagination
			disable-sort
			hide-default-footer
			no-data-text
			fixed-header
		>
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon v-if="!readOnly" small @click="deleteCartItem(item)"
					>mdi-delete</v-icon
				>
			</template>
		</v-data-table>

		<v-divider />
		<v-card-text class="font-weight-bold text-right">
			<v-row no-gutters>
				<v-col cols="4" offset="6"> netAmount </v-col>
				<v-col cols="2">
					{{ cart.netAmount | toCurrency }}
				</v-col>
				<v-col cols="4" offset="6"> vatAmount </v-col>
				<v-col cols="2">
					{{ cart.vatAmount | toCurrency }}
				</v-col>
				<v-col cols="4" offset="6"> totalAmount </v-col>
				<v-col cols="2">
					{{ cart.totalAmount | toCurrency }}
				</v-col>
			</v-row>
		</v-card-text>
		<v-divider />
		<!-- TRANSPORT -->
		<v-card-text class="font-weight-bold text-right">
			<v-row no-gutters align="center">
				<v-col cols="4" offset="6">
					<v-radio-group
						class="my-auto"
						v-model="cart.transportType"
						v-on:change="onChangeTransportType"
						hide-details
						row
					>
						<v-radio label="SHIP" value="SHIP"></v-radio>
						<v-radio label="COLLECT" value="COLLECT"></v-radio>
					</v-radio-group>
				</v-col>

				<v-col cols="2">
					{{ cart.transportTotalAmount | toCurrency }}
				</v-col>
			</v-row>
		</v-card-text>
		<v-divider />
		<v-card-text class="font-weight-black text-right">
			<v-row no-gutters align="center">
				<v-col cols="4" offset="6"> totalPayable </v-col>
				<v-col cols="2">
					{{ cart.totalPayable | toCurrency }}
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
	import { mapGetters } from "vuex";
	export default {
		name: "CartItemTable",
		data() {
			return {
				loading: false,
				tableHeaders: [
					{
						text: "",
						value: "actions",
						align: "center",
					},
					{
						text: "productCode",
						value: "productCode",
					},
					{
						text: "productName",
						value: "productName",
					},
					{
						text: "quantity",
						value: "quantity",
						align: "end",
					},
					{
						text: "unitPriceExclVat",
						value: "unitPriceExclVat",
						align: "end",
					},

					{
						text: "lineNetAmount",
						value: "lineNetAmount",
						align: "end",
					},
				],
			};
		},
		computed: {
			...mapGetters({
				cart: "shoppingCart/cart",
				cartItems: "shoppingCart/cartItems",
				readOnly: "session/readOnly",
			}),
			lastUpdated() {
				if (this.cartItems) {
					if (this.cartItems.length > 0) {
						return this.cartItems[this.cartItems.length - 1]
							.lastUpdated;
					} else {
						return null;
					}
				}
				return null;
			},
		},
		methods: {
			deleteCartItem(pCartItem) {
				this.$store.dispatch("shoppingCart/deleteCartItem", pCartItem);
			},
			onChangeTransportType() {
				let params = {};
				params.transportType = this.cart.transportType;
				this.$store.dispatch("shoppingCart/setTransportType", params);
			},
		},
	};
</script>
